import { FormSchema } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { InspectionTemplate } from '@sw-sw/lib-inspection-templates';
import { find } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { State } from '../../../utils/api/address';
import { Client } from '../../../utils/api/client';
import validator from '../../../utils/FormValidator';
import { env } from '../../../config';

const halfWidthStyle = { flex: '1 1 50%', alignSelf: 'flex-end' };
const emptyDescriptionStyle = { marginTop: '1.5em' };

export const projectInspectorUserRoles = [
  'Admin',
  'Inspector',
  'Area / Assistant Manager',
  'Regional Manager',
  'SWMP Admin',
  'CDOT PE/Designee',
  'Supervising Engineer',
  'Client Inspector',
  'Client Manager',
  'Regional Manager (External)',
];

export function getSchemaLeft(
  clients: Client[],
  inspectionTemplates: InspectionTemplate[],
  states: State[],
  users: any[],
  projectTypes: any[],
  timezones: string[],
  disableClient: boolean = false,
  selectedTemplateId: number | null = null,
  inspectionComplianceRequired: boolean = true,
  optionalComplianceTemplates: string[] = [],
  cgpTrackingIdTemplates: string[] = [],
  ccrTemplates: string[] = [],
  project_client: Client,
  getSelectedValue: any,
  precipitation_edit_lock: boolean,
  evp: boolean,
) {
  ReactTooltip.rebuild();

  const schema: FormSchema = {
    clientId: {
      controlType: UIControlType.select,
      label: 'Client*',
      options: clients,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
      disabled: disableClient,
      getSelectedValue: getSelectedValue,
    },
    permittee: {
      label: 'Permittee',
      description: 'If different than client',
    },
    inspectionTemplateId: {
      controlType: UIControlType.select,
      label: 'Inspection Form Type*',
      options: inspectionTemplates,
      valueKey: 'id',
      labelKey: 'name',
      parse: Number.parseInt,
      validation: { required: true },
    },
    projectType: {
      controlType: UIControlType.select,
      label: "Project Type",
      options: projectTypes,
      valueKey: "id",
      labelKey: "name",
      parse: Number.parseInt,
      validation: { required: true },
    },
    isCcrProject: {
      controlType: UIControlType.checkbox,
      label: 'Use CCR Number',
      toolTipOptions: {
        message:
          'If checked the inspector will be required to have a CCR Number that is included on the certification.',
      },
    },
    inspection_compliance_required: {
      controlType: UIControlType.checkbox,
      label: 'Compliance Signature Required',
    },
    findingComplianceRequired: {
      controlType: UIControlType.checkbox,
      label: 'Completed Findings Required For Compliance',
      toolTipOptions: {
        message:
          'When this is selected, compliance signatures are available, even when open or incomplete corrective action findings are present in the inspection.',
      },
    },
    name: {
      label: 'Project Name*',
      validation: { required: true, maxLength: 355 },
      autoComplete: 'none',
    },
    cgp_tracking_id: {
      label: 'CGP Tracking ID',
      validation: { required: true },
    },
    addressStreet: {
      label: 'Project Address',
      placeholder: 'Street',
      className: 'with-spacing',
      'aria-label': 'Street',
      autoComplete: 'address-line1',
    },
    addressCity: {
      placeholder: 'City',
      className: 'with-spacing',
      'aria-label': 'City',
      autoComplete: 'address-level2',
    },
    addressState: {
      controlType: UIControlType.select,
      options: states,
      valueKey: 'id',
      labelKey: env.REACT_APP_REGION === 'UK' ? 'name' : 'abbreviation',
      placeholder:
        env.REACT_APP_REGION === 'UK' ? 'Select Country' : 'Select State',
      autoComplete: 'address-level1',
      style: { ...halfWidthStyle, alignSelf: 'flex-start' },
    },
    addressZip: {
      placeholder: env.REACT_APP_REGION === 'UK' ? 'Postcode' : 'Zip',
      'aria-label': 'Zip',
      autoComplete: 'postal-code',
      style: { ...halfWidthStyle, alignSelf: 'flex-start' },
      validation: { format: 'zipcode', maxLength: 5 },
      parse: validator.parseNumber,
    },
    latitude: {
      label: 'Location*',
      placeholder: 'Latitude',
      'aria-label': 'Latitude',
      style: halfWidthStyle,
      parse: validator.parseDecimal,
      // inputMode: "decimal",
      validation: { required: true },
    },
    longitude: {
      placeholder: 'Longitude',
      'aria-label': 'Longitude',
      style: halfWidthStyle,
      parse: validator.parseDecimal,
      // inputMode: "decimal",
      validation: { required: true },
    },
    timezone: {
      controlType: UIControlType.select,
      options:
        env.REACT_APP_REGION === 'UK'
          ? [{ label: 'GMT' }]
          : timezones.map((label) => ({ label })),
      valueKey: 'label',
      labelKey: 'label',
      placeholder: 'Select Timezone',
    },
    inspector_user_id: {
      label: 'Inspector*',
      controlType: UIControlType.select,
      options: users,
      valueKey: 'id',
      labelKey: 'name',
      placeholder: 'Please select a user',
      noOptionMessage: 'There are no users available.',
      validation: { required: true },
    },
    precipitation_flag: {
      label: 'Precipitation',
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm',
      disabled: precipitation_edit_lock
        ? true
        : project_client
        ? !project_client.precipitation_client_project_flag
        : false,
    },
    precipitation_threshold: {
      label: `Rain Threshold ${
        process.env.REACT_APP_REGION
          ? process.env.REACT_APP_REGION === 'US'
            ? '(inches)'
            : '(mm)'
          : ''
      }`,
      validation: { required: false },
      autoComplete: 'none',
      parse: validator.parseDecimal,
      style: halfWidthStyle,
      disabled: precipitation_edit_lock
        ? true
        : project_client
        ? !project_client.precipitation_client_project_flag
        : false,
    },
    snow_threshold: {
      label: `Snow Threshold ${
        process.env.REACT_APP_REGION
          ? process.env.REACT_APP_REGION === 'US'
            ? '(inches)'
            : '(mm)'
          : ''
      }`,
      validation: { required: false },
      autoComplete: 'none',
      parse: validator.parseDecimal,
      style: halfWidthStyle,
      disabled: precipitation_edit_lock
        ? true
        : project_client
        ? !project_client.precipitation_client_project_flag
        : false,
    },
  };

  // the selected template, if any
  const tmpl: InspectionTemplate = find(inspectionTemplates, {
    id: selectedTemplateId || undefined,
  }) || { name: 'unknown', isUserTemplate: false };

  /**
   * CCR Number option is only available for templates
   * in appContext.ccrTemplates
   */
  if (!ccrTemplates.includes(tmpl.name)) {
    delete schema.isCcrProject;
  }

  /**
   * "compliance required" option
   * only display for selected templates
   */
  if (!selectedTemplateId || !optionalComplianceTemplates.includes(tmpl.name)) {
    delete schema.inspection_compliance_required;
  }

  if (
    !selectedTemplateId ||
    !optionalComplianceTemplates.includes(tmpl.name) ||
    (optionalComplianceTemplates.includes(tmpl.name) &&
      !inspectionComplianceRequired)
  ) {
    delete schema.findingComplianceRequired;
  }

  /**
   * "CGP ID" option
   *
   * only displayed for Lennar template
   */
  if (!selectedTemplateId || !cgpTrackingIdTemplates.includes(tmpl.name)) {
    delete schema.cgp_tracking_id;
  }

  if (evp) {
    delete schema.inspectionTemplateId;
    delete schema.precipitation_flag;
    delete schema.precipitation_threshold;
    delete schema.snow_threshold;
  }

  return schema;
}

export function getSchemaRight(
  docTypes: any[],
  docGroups: any[],
  divisionInspectionIntervals: any[],
): FormSchema {
  return {
    // break this label into it's own, so it doesn't render as half width
    timeLine: {
      controlType: UIControlType.plainText,
      label: 'Anticipated Project Timeline',
      style: { margin: '0' },
    },
    startDate: {
      controlType: UIControlType.date,
      placeholder: 'Start date',
      'aria-label': 'Project start date',
      style: halfWidthStyle,
    },
    endDate: {
      controlType: UIControlType.date,
      placeholder: 'End date',
      'aria-label': 'Project end date',
      style: halfWidthStyle,
      className: 'right-popper',
      // popperPlacement: "top-end",
    },
    next_inspection_date: {
      controlType: UIControlType.date,
      label: `Next Routine Inspection Date`,
      className: 'right-popper',
      // popperPlacement: "top-end",
      validation: {
        minDate: moment()
          .milliseconds(0)
          .seconds(0)
          .minutes(0)
          .hour(0)
          .toDate(),
      },
      style: emptyDescriptionStyle,
    },
    inspection_interval_id: {
      label: 'Routine Inspection Interval*',
      controlType: UIControlType.select,
      placeholder: 'Select Interval...',
      options: divisionInspectionIntervals,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
    },
    notes: {
      label: 'Additional Notes',
      controlType: UIControlType.textarea,
    },
    document_group: {
      label: 'Regulations*',
      controlType: UIControlType.select,
      placeholder: 'Select document group...',
      options: docGroups,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
    },
    docTypes: {
      label: 'Document Types',
      controlType: UIControlType.checkboxGroup,
      options: docTypes,
      valueKey: 'id',
      labelKey: 'name',
      showSelectAll: true,
    },
  };
}

export function getProjectInformationSchemaLeft(
  clients: Client[],
  inspectionTemplates: InspectionTemplate[],
  states: State[],
  timezones: string[],
  disableClient: boolean = false,
  selectedTemplateId: number | null = null,
  inspectionComplianceRequired: boolean = true,
  optionalComplianceTemplates: string[] = [],
  cgpTrackingIdTemplates: string[] = [],
  ccrTemplates: string[] = [],
  getSelectedValue: any,
  evp: boolean,
): FormSchema {
  ReactTooltip.rebuild();

  const schema: FormSchema = {
    clientId: {
      controlType: UIControlType.select,
      label: 'Client*',
      options: clients,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
      disabled: disableClient,
      getSelectedValue: getSelectedValue,
    },
    permittee: {
      label: 'Permittee (If different than client)',
    },
    inspectionTemplateId: {
      controlType: UIControlType.select,
      label: 'Inspection Form Type*',
      options: inspectionTemplates,
      valueKey: 'id',
      labelKey: 'name',
      parse: Number.parseInt,
      validation: { required: true },
    },
    name: {
      label: 'Project Name*',
      validation: { required: true, maxLength: 355 },
      autoComplete: 'none',
    },
    addressStreet: {
      label: 'Project Address',
      placeholder: 'Street',
      className: 'with-spacing',
      'aria-label': 'Street',
      autoComplete: 'address-line1',
    },
    addressCity: {
      placeholder: 'City',
      className: 'with-spacing',
      'aria-label': 'City',
      autoComplete: 'address-level2',
    },
    addressState: {
      controlType: UIControlType.select,
      options: states,
      valueKey: 'id',
      labelKey: env.REACT_APP_REGION === 'UK' ? 'name' : 'abbreviation',
      placeholder:
        env.REACT_APP_REGION === 'UK' ? 'Select Country' : 'Select State',
      autoComplete: 'address-level1',
      style: { ...halfWidthStyle, alignSelf: 'flex-start' },
    },
    addressZip: {
      placeholder: env.REACT_APP_REGION === 'UK' ? 'Postcode' : 'Zip',
      'aria-label': 'Zip',
      autoComplete: 'postal-code',
      style: { ...halfWidthStyle, alignSelf: 'flex-start' },
      validation: { format: 'zipcode', maxLength: 5 },
      parse: validator.parseNumber,
    },
    latitude: {
      label: 'Location*',
      placeholder: 'Latitude',
      'aria-label': 'Latitude',
      parse: validator.parseDecimal,
      validation: { required: true },
      style: halfWidthStyle,
    },
    longitude: {
      placeholder: 'Longitude',
      'aria-label': 'Longitude',
      parse: validator.parseDecimal,
      validation: { required: true },
      style: halfWidthStyle,
    },
    timezone: {
      controlType: UIControlType.select,
      options:
        env.REACT_APP_REGION === 'UK'
          ? [{ label: 'GMT' }]
          : timezones.map((label) => ({ label })),
      valueKey: 'label',
      labelKey: 'label',
      placeholder: 'Select Timezone',
    },
    isCcrProject: {
      controlType: UIControlType.checkbox,
      label: 'Use CCR Number',
      toolTipOptions: {
        message:
          'If checked the inspector will be required to have a CCR Number that is included on the certification.',
      },
    },
    inspection_compliance_required: {
      controlType: UIControlType.checkbox,
      label: 'Compliance Signature Required',
    },
    findingComplianceRequired: {
      controlType: UIControlType.checkbox,
      label: 'Completed Findings Required For Compliance',
      toolTipOptions: {
        message:
          'When this is selected, compliance signatures are available, even when open or incomplete corrective action findings are present in the inspection.',
      },
    },
    cgp_tracking_id: {
      label: 'CGP Tracking ID',
      validation: { required: true },
    },
  };

  const tmpl: InspectionTemplate = find(inspectionTemplates, {
    id: selectedTemplateId || undefined,
  }) || { name: 'unknown', isUserTemplate: false };

  if (!ccrTemplates.includes(tmpl.name)) {
    delete schema.isCcrProject;
  }

  if (!selectedTemplateId || !optionalComplianceTemplates.includes(tmpl.name)) {
    delete schema.inspection_compliance_required;
  }

  if (
    !selectedTemplateId ||
    !optionalComplianceTemplates.includes(tmpl.name) ||
    (optionalComplianceTemplates.includes(tmpl.name) &&
      !inspectionComplianceRequired)
  ) {
    delete schema.findingComplianceRequired;
  }

  if (!selectedTemplateId || !cgpTrackingIdTemplates.includes(tmpl.name)) {
    delete schema.cgp_tracking_id;
  }

  if (evp) {
    delete schema.inspectionTemplateId;
  }

  return schema;
}

export function getProjectInformationSchemaRight(
  users: any[],
  primaryContactUsers: any[],
  projectTypes: any[],
  divisionInspectionIntervals: any[],
  docGroups: any[],
): FormSchema {
  ReactTooltip.rebuild();

  const schema: FormSchema = {
    timeLine: {
      controlType: UIControlType.plainText,
      label: 'Anticipated Project Timeline',
      style: { margin: '0' },
    },
    startDate: {
      controlType: UIControlType.date,
      placeholder: 'Start date',
      'aria-label': 'Project start date',
      style: halfWidthStyle,
    },
    endDate: {
      controlType: UIControlType.date,
      placeholder: 'End date',
      'aria-label': 'Project end date',
      style: halfWidthStyle,
      className: 'right-popper',
    },
    next_inspection_date: {
      controlType: UIControlType.date,
      label: `Next Routine Inspection Date`,
      validation: {
        minDate: moment()
          .milliseconds(0)
          .seconds(0)
          .minutes(0)
          .hour(0)
          .toDate(),
      },
      style: { ...halfWidthStyle },
    },
    inspection_interval_id: {
      label: 'Routine Inspection Interval*',
      controlType: UIControlType.select,
      placeholder: 'Select Interval...',
      options: divisionInspectionIntervals,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
    },
    projectType: {
      controlType: UIControlType.select,
      label: 'Project Type*',
      options: projectTypes,
      valueKey: 'id',
      labelKey: 'name',
      parse: Number.parseInt,
      validation: { required: true },
    },
    notes: {
      label: 'Additional Notes',
      controlType: UIControlType.textarea,
      style: { ...halfWidthStyle, alignSelf: 'flex-start' },
    },
    primary_contact_user_id: {
      label: 'Primary Contact',
      controlType: UIControlType.select,
      options: primaryContactUsers,
      disabled: primaryContactUsers.length === 0,
      valueKey: 'id',
      labelKey: 'name',
      placeholder: 'Please select a user',
      noOptionMessage: 'There are no users available.',
    },
    inspector_user_id: {
      label: 'Inspector*',
      controlType: UIControlType.select,
      options: users,
      valueKey: 'id',
      labelKey: 'name',
      placeholder: 'Please select a user',
      noOptionMessage: 'There are no users available.',
      validation: { required: true },
    },
    document_group: {
      label: 'Regulations*',
      controlType: UIControlType.select,
      placeholder: 'Select document group...',
      options: docGroups,
      valueKey: 'id',
      labelKey: 'name',
      validation: { required: true },
    },
  };

  return schema;
}

export function getDocumentType(docTypes: any[]): FormSchema {
  return {
    docTypes: {
      label: 'Document Types',
      controlType: UIControlType.checkboxGroup,
      options: docTypes,
      valueKey: 'id',
      labelKey: 'name',
      showSelectAll: true,
    },
  };
}

export function getPrecipitationFields(
  precipitation_edit_lock: boolean,
  project_client: Client,
): FormSchema {
  return {
    precipitation_threshold: {
      label: `Rain Threshold ${
        process.env.REACT_APP_REGION
          ? process.env.REACT_APP_REGION === 'US'
            ? '(inches)'
            : '(mm)'
          : ''
      }`,
      validation: { required: false },
      autoComplete: 'none',
      parse: validator.parseDecimal,
      style: halfWidthStyle,
      disabled: precipitation_edit_lock
        ? true
        : project_client
        ? !project_client.precipitation_client_project_flag
        : false,
    },
    snow_threshold: {
      label: `Snow Threshold ${
        process.env.REACT_APP_REGION
          ? process.env.REACT_APP_REGION === 'US'
            ? '(inches)'
            : '(mm)'
          : ''
      }`,
      validation: { required: false },
      autoComplete: 'none',
      parse: validator.parseDecimal,
      style: halfWidthStyle,
      disabled: precipitation_edit_lock
        ? true
        : project_client
        ? !project_client.precipitation_client_project_flag
        : false,
    },
  };
}
