import React, { Component, createRef, useContext } from 'react';
import { string, bool, func, arrayOf, object, number, array } from 'prop-types';
import { noop } from 'lodash';
import ReactTooltip from 'react-tooltip';
import c from 'classnames';

import { Button, Modal } from '@sw-sw/lib-ui';
import UploadInput from '../../FormInput/Upload';
import { print } from '../../../utils/print';
import FileRow from './FileRow';
import uploadApi from '../../../utils/api/upload';
import RolesContext from '../../../contexts/RolesContext';
import Tooltip from '@mui/material/Tooltip';
import ReactToolTip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import AppContext from "../../../contexts/AppContext";
import TenantStore from "../../../contexts/TenantContext"
const SHOW_LESS_LIMIT = 3;

/**
 * List of file cards, visually grouped beneath a group name heading
 */
export class FileCardListUI extends Component {
  static propTypes = {
    groupName: string.isRequired,
    documents: arrayOf(object).isRequired,
    allowedTypes: arrayOf(string), // array of mime_types

    // feature flags
    // (default off)
    canEdit: bool,
    canPrint: bool,
    canDelete: bool,
    canReplace: bool,
    canRename: bool,
    canRestore: bool,
    canArchive: bool,
    hideTitle: bool,
    // (default on)
    canUpload: bool,
    canDownload: bool,

    // callbacks for functionality handled in this component and its children
    onUpload: func,

    // functionality handled by the implementing/parent component
    handleDelete: func,
    handleEdit: func,
    handleRename: func,
    handleReplace: func,
    handleRestore: func,
    handleArchive: func,
    handleDownload: func,
    label: string,
    permCheck: func.isRequired,
    showMoreThreshold: number,
    minimumImageDimensions: object,
    maxFileSize: number,
    isProjectDocumentsPage: bool,
    doTypes: array,
    setShouldReloadProjectDocumentsPage: bool,
    isAdmin: bool,
    canTenantEdit: bool,
    location: string
  };

  static defaultProps = {
    onUpload: noop,
    handleDelete: noop,
    canUpload: true,
    canDownload: true,
    canRename: true,
    showMoreThreshold: SHOW_LESS_LIMIT,
    maxFileSize: 75000000,
    minimumImageDimensions: {
      width: 50,
      height: 50,
    },
  };

  constructor(props) {
    super(props);

    this.tableRef = createRef();
  }

  state = {
    isShowingMore: false,
    showMoveDocModal: false,
    destinationFolderId: null,
    selectedDocument: [],
    modalErrorMsg: "",
    isLoadingForModal: false
  };

  toggleShowMore() {
    this.setState({ isShowingMore: !this.state.isShowingMore });
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      groupName,
      documents,
      onUpload,
      handleDelete,
      handleEdit,
      handleRename,
      handleReplace,
      handleRestore,
      handleArchive,
      handleDownload,
      permCheck,
      label,
      canEdit,
      canPrint,
      canDelete,
      canReplace,
      canRename,
      canUpload,
      canDownload,
      hideTitle,
      allowedTypes,
      minimumImageDimensions,
      maxFileSize,
      onMove,
      onDrop,
      canDrag,
      document_date,
      isAdmin,
      isProjectDocumentsPage,
      setShouldReloadProjectDocumentsPage,
      canTenantEdit,
      location
    } = this.props;

    var { showMoreThreshold } = this.props;

    if (!showMoreThreshold) {
      showMoreThreshold = SHOW_LESS_LIMIT;
    }

    const docTypesWithCheckboxExcluded = ["Regulations", "Archived Maps", "Active Site Maps"]
    const docTypesExcludedFromDestinationOptions = ["Regulations", "Archived Maps", "Active Site Maps"]

    const isPublic = window.location.pathname.startsWith('/public');

    const userCanMove = permCheck("update", "Project Documents Move");

    return isPublic && groupName === 'Active Site Maps' ? (
      <></>
    ) : (
      <section className='document-group'>
        {hideTitle ? null : (
          <div className='document-group__header'>
            {groupName === 'Active Site Maps' ? (
              <Tooltip
                title={
                  'Upload map files to be used in the active site map editor'
                }
                placement='top'
                arrow
              >
                <h3 className='document-group__title'>
                  {'Editor - Site Maps'}
                </h3>
              </Tooltip>
            ) : (
              <h3 className='document-group__title'>
                {`${groupName} ` }
                {groupName === 'Fixed Details' && (
                  <span
                    className='help-icon'
                    data-tip={
                      'Upload documents that do not need to be associated with a legend item and will always be available within a project.'
                    }
                    data-delay-show='125'
                    onMouseOver={() => ReactToolTip.show()}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                )}
              </h3>
            )}

            <div className="document-group__header-right">
              {
                this.state.selectedDocument.length ?
                <FontAwesomeIcon icon={faArrowsAlt} color="rgb(56,89,128)" onClick={() => this.setState({showMoveDocModal: true})}/>
                : <></>
              }
              {canUpload &&
                onUpload &&
                permCheck('update', label) &&
                permCheck('create', 'Uploads') &&
                groupName !== 'Archived Maps' && (
                  <div className='document-group__item-upload'>
                    <UploadInput
                      onUpload={(newFile) =>
                        onUpload ? onUpload(newFile) : null
                      }
                      imageDimensions={minimumImageDimensions}
                      allowedTypes={allowedTypes}
                      maxFileSize={maxFileSize}
                    />
                  </div>
              )}
            </div>

            <Modal
              title="Select Destination Folder"
              subTitle="Choose the destination folder where you want to move the document."
              show={this.state.showMoveDocModal}
              submitBtnText="Confirm"
              handleSubmit={() => {
                if(this.state.destinationFolderId === null){
                  this.setState({modalErrorMsg: "Choose destination Folder"})

                  return
                }
                else{
                  this.setState({isLoadingForModal: true})
                  this.setState({modalErrorMsg: ""})

                  uploadApi.move(this.state.selectedDocument[0].ProjectUploadModel.project_id, this.state.destinationFolderId, this.state.selectedDocument.map(ele => ele.id))
                  .then(() => {
                    setShouldReloadProjectDocumentsPage((prevState) => !prevState)
                    this.setState({selectedDocument: []})
                    this.setState({isLoadingForModal: false})
                    this.setState({showMoveDocModal: false})
                  })
                  .catch(error => console.log(error))
                  
                }
              }}
              disableSubmit={this.state.isLoadingForModal}
              handleClose={() => {
                this.setState({modalErrorMsg: ""})
                this.setState({destinationFolderId: null})
                this.setState({showMoveDocModal: false})
              }}
              cancelBtn={true}
              errorMsg={this.state.modalErrorMsg}
            >
              <select className='document-group__destination-modal-dropdown' onChange={(event) => this.setState({destinationFolderId: Number(event.target.value)})}>
                <option value="" hidden>Choose Destination Folder</option>
                {
                  this.props.docTypes ? this.props.docTypes
                  .filter(ele => (ele.name !== groupName && !docTypesExcludedFromDestinationOptions.includes(ele.name)))
                  .map(ele => {
                    return <option value={ele.id}>
                      { ele.name.length > 45 ? 
                        `${ele.name.substring(0, 45)}...` :
                        ele.name
                      }
                      </option>
                  }
                ):
                <></>}
              </select>
            </Modal>
          </div>
        )}
        {/* empty state */}
        {!documents.length ? (
          <div className='document-group__empty-state'>
            <p className='document-group__title--empty-state'>
              No documents have been uploaded
            </p>
          </div>
        ) : null}
        {/* show the upload button here, when title is hidden */}
        {hideTitle &&
        permCheck('create', 'Uploads') &&
        permCheck('update', label) ? (
          <UploadInput
            onUpload={(newFile) => (onUpload ? onUpload(newFile) : null)}
            allowedTypes={allowedTypes}
            maxFileSize={maxFileSize}
            imageDimensions={minimumImageDimensions}
            className={c({
              'upload-control--cta': !documents.length,
              'upload-control--row': documents.length > 0,
            })}
            showHelpText
          />
        ) : null}
        {/* non-empty */}
        {documents.length ? (
          <table className='document-group__items' ref={this.tableRef}>
            <thead>
              <tr className='document-group__list-header'>
                {canDrag ? <th className='document-group__list-th'></th> : null}
                {(isProjectDocumentsPage && userCanMove && !docTypesWithCheckboxExcluded.includes(groupName)) ? 
                  <td></td>
                  : <></>
                }
                <th className='document-group__list-th'>Name</th>
                <th className='document-group__list-th'>Type</th>
                {document_date || isAdmin ? (
                  <>
                    <th className='document-group__list-th document-group__list-th--hide-on-mobile'>
                      Created
                    </th>
                    <th className='document-group__list-th document-group__list-th--hide-on-mobile'>
                      Updated
                    </th>
                  </>
                ) : null}
                <th className='document-group__list-th'>
                  {/* Leave empty for CSS grid so table actions have room */}
                </th>
              </tr>
            </thead>

            <tbody>
              {documents
                .slice(
                  0,
                  this.state.isShowingMore
                    ? documents.length
                    : this.props.showMoreThreshold,
                )
                .filter(ele => {
                  if(!ele.ProjectUploadModel){
                    return true
                  }

                  return ele.ProjectUploadModel.status !== "Replaced"
                
                })
                .map((doc, index) => {
                  let projectId = null;

                  if (
                    doc.ProjectUploadModel &&
                    doc.ProjectUploadModel.project_id
                  ) {
                    projectId = doc.ProjectUploadModel.project_id;
                  } else if (doc.projectId) {
                    projectId = doc.projectId;
                  }

                  return (
                    <FileRow
                      key={doc.id}
                      projectId={projectId}
                      index={index}
                      fileName={doc.name}
                      document={doc}
                      canDownload={canDownload}
                      canDrag={canDrag}
                      downloadUrl={
                        canDownload
                          ? uploadApi.getDownloadUrl(
                              doc.GUID,
                              null,
                              false,
                              Date.now(),
                              projectId,
                            )
                          : ''
                      }
                      canEdit={canEdit && (doc.mime_type === 'application/pdf' || (doc.upload && doc.upload.mime_type === 'application/pdf')) && canTenantEdit}
                      canPrint={!doc.docTypeName && canPrint && doc.mime_type === 'application/pdf'}
                      canDelete={location === 'bmp-docs' ? ((doc.project_id || doc.ProjectUploadModel) && !doc.docTypeName && canDelete): (!doc.docTypeName && canDelete)}
                      canReplace={location === 'bmp-docs' ? ((doc.project_id || doc.ProjectUploadModel) && !doc.docTypeName && canReplace): (!doc.docTypeName && canReplace)}
                      canRename={location === 'bmp-docs' ? ((doc.project_id || doc.ProjectUploadModel) && !doc.docTypeName && canRename): (!doc.docTypeName && canRename)}
                      canArchive={
                        groupName === 'Active Site Maps' &&
                        permCheck('all', 'Archive')
                      }
                      canRestore={
                        groupName === 'Archived Maps' &&
                        permCheck('all', 'Restore')
                      }
                      handleDownload={() => handleDownload(doc)}
                      handleArchive={() => handleArchive(doc)}
                      handleRestore={() => handleRestore(doc)}
                      handleDelete={() => handleDelete(doc)}
                      handleEdit={() => handleEdit(doc)}
                      handleRename={() => handleRename(doc)}
                      handleReplace={(newDoc) => handleReplace(newDoc, doc)}
                      handlePrint={() =>
                        canPrint ? print(doc, projectId) : null
                      }
                      permCheck={permCheck}
                      label={label}
                      maxFileSize={maxFileSize}
                      minimumImageDimensions={minimumImageDimensions}
                      parentRef={this.tableRef}
                      onMove={onMove}
                      onDrop={onDrop}
                      documentGroupName={groupName}
                      document_date={document_date}
                      isProjectDocumentsPage={isProjectDocumentsPage}
                      selectedDocument={this.state.selectedDocument}
                      setSelectedDocument={(selectedIds) => this.setState({selectedDocument: selectedIds})}
                      userCanMove={userCanMove}
                    />
                  );
                })}
            </tbody>
          </table>
        ) : null}
        {documents.length > this.props.showMoreThreshold ? (
          <Button
            small={true}
            className='document-group__expand-action'
            onClick={() => {
              this.toggleShowMore();
            }}
          >
            Show {this.state.isShowingMore ? 'Less' : 'More'}
          </Button>
        ) : null}
      </section>
    );
  }
}

const FileCardList = ({ permCheck, ...props }) => {
  const roleStore = useContext(RolesContext);
  const appContext = useContext(AppContext);
  const isAdmin = appContext.get("user").roleName === 'Admin';
  const canTenantEdit = useContext(TenantStore);

  if (!permCheck) {
    permCheck = roleStore.userHasPermission;
  }

  return <FileCardListUI {...props} permCheck={permCheck} isAdmin={isAdmin} canTenantEdit={canTenantEdit.tenantHasFeature('PDF Editing')} />;
};

export default FileCardList;
